@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
$bg-color: #fff;
$navbar-bg-color: #424141;
$navbar-color: #fff;
$sidebar-bg-color: #fff;
$sidebar-color: #010208;
$sidebar-icon-color: #c4cff9;
$border-color: #f2f3f8;
body {
    //font-family: "Poppins", "Roboto", sans-serif;
    font-family: "Segoe UI";
    //font-size: 0.9rem;
}

.wrapper.default-theme {
    background: $bg-color;
    nav.navbar {
        background: $navbar-bg-color;
        border-color: rgba($navbar-color, 0.3);
        .navbar-brand {
            color: lighten($navbar-color, 25%);
        }
        .left-nav {
            input.navbar-search {
                background: #5b6582;
                color: $navbar-color;
                &::placeholder {
                    color: rgba($navbar-color, 0.6);
                }
            }
        }
        .right-nav {
            background: $navbar-bg-color;
        }
        .nav-link {
            color: $navbar-color;
            i {
                border-color: $navbar-color;
                background: #5b6582;
            }
            &:hover {
                color: lighten($navbar-color, 15%);
            }
        }
        .nav-alert {
            border-color: #2f3546;
            &.notifications {
                background-color: #ff61c5;
                box-shadow: 0px 0px 4px #ff61c5;
            }
            &.messages {
                background-color: #61b8ff;
                box-shadow: 0px 0px 4px #61b8ff;
            }
        }
    }
    main {
        .overlay {
            background: #5b6582;
        }
        .sidebar {
            background: $sidebar-bg-color;
            border-right: 1px solid #424141;
            >div {
                border-color: rgba($sidebar-color, 0.1);
            }
            a.sidebar-link {
                color: $sidebar-color;
            }
            .sidebar-profile {
                .name {
                    color: $sidebar-color;
                }
                .role {
                    color: rgba($sidebar-color, 0.8);
                }
            }
            .sidebar-menu {
                .menu-header {
                    color: rgba($sidebar-color, 0.5);
                }
                .menu-items {
                    a {
                        color: rgba($sidebar-color, 0.7);
                        i {
                            color: $sidebar-icon-color;
                            color: #abb7da;
                            color: rgba(140, 165, 239, 0.6);
                            border: none;
                            width: auto;
                            font-size: 1rem;
                        }
                        &:hover,
                        &.active {
                            background: #f5f5f9;
                            color: rgba($sidebar-color, 0.9);
                            i {
                                color: #95b0ff;
                            }
                        }
                    }
                }
            }
        }
    }
    .btn {
        //font-size: 0.9rem;
        &.btn-lightning {
            color: #f0f3ff;
            background-color: #5b6582;
            border-color: #5b6582;
            &:hover {
                background-color: #4c5671;
            }
        }
        &.btn-outline-lightning {
            color: #5b6582;
            background-color: transparent;
            border-color: #5b6582;
            &:hover {
                color: #f0f3ff;
                background-color: #5b6582;
                border-color: #5b6582;
            }
        }
    }
    .form-control {
        //font-size: 0.9rem;
    }
    .text-lightning {
        color: #98a4c7;
    }
}