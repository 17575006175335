.nav .nav-tabs {
    // padding-left: 30px;
    // background-color: #4241411f;
    // font-size: 24px;
    // border-bottom: 0px;
}

.nav-tabs {
    background-color: #4241411f;
    padding-left: 30px !important;
    border-bottom: 0px;
}

.nav-link {
    display: block;
    padding: 0.2rem 1rem;
}

.tab-content {
    border: 0px solid #424141;
    border-top: 0px solid #ff9900;
    border-bottom: 0px solid #ff9900;
    padding: 30px 30px !important;
}

.nav-tabs .active {
    border-top-color: #ff9900 !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border-style: solid;
    border-width: 3px 1px 1px !important;
    border-radius: 3px 3px 0 0 !important;
}