.card {
  .card-icon {
    i {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-radius: 50%;
      font-size: 3rem;
    }
  }
  .legend{
    span:first-child{
      width: 10px;
      height: 10px;
    }
  }
}
