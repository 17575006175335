.card {
    margin-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0;
    .card-title {
        color: #fff;
        background-color: #424141b3;
        padding: 0px 5px !important;
        h5 {
            font-weight: normal !important;
        }
    }
    .card-icon {
        i {
            color: #98a4c7;
        }
    }
    .card-footer {
        border-top: 1px solid $border-color;
        background: none;
    }
    .card-title {
        border-bottom: 1px solid $border-color;
    }
    .card-content {
        padding: 5px 5px;
    }
}


/*
*   Estilo para colocar un icono del lado derecho del titulo
*/

.card-title::before {
    content: "\f15c";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    vertical-align: middle;
    right: 5px;
    font-size: 18px;
}