$navbar-height: 60px;
$sidebar-width: 260px;
$sidebar-pinned-wdth: 70px;
$titulo-colorfondo: #bdbaba;

/* Colors */

.color.white {
    background: #f0f0f0;
    color: #555;
}

.color.black {
    background: #333;
}

.color.grey {
    background: #999;
}

.color.blue {
    background: #39d;
}

.color.green {
    background: #2c7;
}

.color.turquoise {
    background: #1b9;
}

.color.purple {
    background: #95b;
}

.color.red {
    background: #e43;
}

.color.orange {
    background: #e82;
}

.color.yellow {
    background: #ec0;
}