/*
    estilo de callout, estos no estan dentro de bootstrap
*/

.callout {
    padding: 10px;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 4px;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.callout-xl {
    margin-left: 0px;
}

.callout-lg {
    margin-left: 5px;
}

.callout-md {
    margin-left: 10px;
}

.callout-sm {
    margin-left: 15px;
}

.callout-xs {
    margin-left: 20px;
}

.callout h4 {
    margin-top: 0;
    margin-bottom: 5px;
}

.callout p:last-child {
    margin-bottom: 0;
}

.callout code {
    border-radius: 4px;
}

.callout+.callout {
    margin-top: -5px;
}

.callout-default {
    border-left-color: #ff9900;
    background-color: #424141;
    color: #fff;
    padding: 10px;
}

.callout-botones {
    border-left-color: #424141;
    background-color: #e9e9e9;
    color: #fff;
    padding-bottom: 3px;
    border: 1px solid #ccc;
    border-radius: 1px;
}

.callout-titulo {
    border-left-color: #ff9900;
    background-color: #424141;
    color: #fff;
    padding: 10px;
    .subtitulo {
        color: #fc0;
        font-size: 10px;
    }
}

.callout-default h4 {
    color: #777;
}

.callout-primary {
    border-left-color: #428bca;
    background-color: rgb(66, 139, 202, 0.2);
}

.callout-primary h4 {
    color: #428bca;
}

.callout-success {
    border-left-color: #5cb85c;
    background-color: #efffe8;
}

.callout-success h4 {
    color: #5cb85c;
}

.callout-danger {
    border-left-color: #d9534f;
    background-color: #fcf2f2;
}

.callout-danger h4 {
    color: #d9534f;
}

.callout-warning {
    border-left-color: #f0ad4e;
    background-color: #fefbed;
}

.callout-warning h4 {
    color: #f0ad4e;
}

.callout-info {
    border-left-color: #5bc0de;
    background-color: #f0f7fd;
}

.callout-info h4 {
    color: #5bc0de;
}


/*
    Transparencia de los callout
*/

.callout-default.transparent {
    background-color: rgb(247, 247, 249, 0.7);
    /*#f7f7f9*/
}

.callout-success.transparent {
    background-color: rgb(239, 255, 232, 0.7);
    /*#efffe8*/
}

.callout-warning.transparent {
    background-color: rgb(254, 251, 237, 0.7);
    /*#fefbed*/
}

.callout-danger.transparent {
    background-color: rgb(252, 242, 242, 0.7);
    /*#fcf2f2*/
}

.callout-info.transparent {
    background-color: rgb(240, 247, 253, 0.7);
    /*#f0f7fd*/
}

.callout-primary.transparent {
    background-color: rgb(66, 139, 202, 0.7);
    /*#f0f7fd*/
}

.callout.disabled {
    opacity: 0.4;
}