// Termina estilo de componentes de entrada
.e-input-group.e-date-wrapper.e-custom-style:not(.e-float-icon-left)::before,
.e-input-group.e-date-wrapper.e-custom-style:not(.e-float-icon-left)::after {
    background: #ff9000;
}

.e-date-wrapper.e-custom-style input.e-input::selection {
    background: #000;
}

.e-date-wrapper.e-custom-style input.e-input::-moz-selection {
    background: #000;
}

.e-calendar .e-content td.e-disabled .e-day,
.e-calendar .e-content td.e-disabled:hover .e-day,
.e-calendar .e-content td.e-disabled:focus .e-day {
    color: #ff9900;
    font-weight: bold;
}

.e-date-wrapper.e-custom-style .e-input-group-icon.e-date-icon.e-icons,
.e-date-wrapper.e-custom-style .e-input {
    // Icono del componente
    color: #424141;
}

.e-date-wrapper.e-custom-style input.e-input::selection {
    background: #424141;
}

.e-date-wrapper.e-custom-style input.e-input::-moz-selection {
    background: #424141;
}

.e-date-wrapper.e-custom-style .e-input-group-icon.e-date-icon.e-icons.e-active {
    // Icono del Componente al seleccionarse
    color: #ff9900;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
.e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    border: 1px solid #ced4da;
}

//************************************************************
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: #ff9000;
    border-color: #ff9000;
    //box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px #f6be69;
}

.e-control input.e-input,
.e-control .e-input-group input,
.e-control .e-input-group input.e-input,
.e-control .e-input-group.e-control-wrapper input,
.e-control .e-input-group.e-control-wrapper input.e-input,
.e-control .e-float-input input,
.e-control .e-float-input.e-control-wrapper input,
.e-control.e-input-group input,
.e-control.e-input-group input.e-input,
.e-control.e-input-group.e-control-wrapper input,
.e-control.e-input-group.e-control-wrapper input.e-input,
.e-control.e-float-input input,
.e-control.e-float-input.e-control-wrapper input {
    box-sizing: content-box;
    padding-left: 5px;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #ff5722;
    border-color: transparent;
    color: #fff;
}

.e-listview .e-list-item.e-focused .e-checkbox-wrapper .e-frame.e-check,
.e-listview .e-list-item.e-focused .e-css.e-checkbox-wrapper .e-frame.e-check {
    background-color: #ff5722;
    border-color: transparent;
    color: #fff;
}

.e-listbox-wrapper .e-list-item.e-selected,
.e-listbox-container .e-list-item.e-selected {
    background-color: #ff5722d1;
    color: rgba(0, 0, 0, 0.87);
}

.e-listbox-wrapper .e-list-item:hover:not(.e-selected):not(.e-disabled),
.e-listbox-wrapper .e-list-item:hover.e-selected.e-checklist:not(.e-disabled),
.e-listbox-container .e-list-item:hover:not(.e-selected):not(.e-disabled),
.e-listbox-container .e-list-item:hover.e-selected.e-checklist:not(.e-disabled) {
    background-color: #ff5722d1;
    border-color: transparent;
    color: rgba(0, 0, 0, 0.87);
}