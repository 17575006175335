@import "./partials/variables";
@import "./partials/navbar";
@import "./partials/sidebar";
@import "./partials/themes";
@import "./partials/dashboard";
@import "./partials/ribbonCorner";
@import "./partials/ribbonLabel";
@import "./partials/ribbonHorizontal";
@import "./partials/titulo";
@import "./partials/boton";
@import "./partials/tabla";
@import "./partials/tabs";
@import "./partials/callout";
@import "./partials/cards";
@import "./partials/images";
@import "./partials/componentes";
.icon-style {
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    border-radius: 50%;
    border: 1px solid;
    font-size: 0.9rem;
}

body {
    height: 100vh;
    margin: 0;
    padding-top: 60px;
    font-size: 14px;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

li.listas {
    list-style: none;
    margin: 10px auto;
}

ul.listas {
    padding-inline-start: 0px;
}


/* Sobre escribimos los padding izquierdo y derecho de todas las columnas del framework*/


/*
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
    padding-right: 0px;
    padding-left: 0px;
}
*/


/*Color del borde de componentes seleccionados*/

.form-control:focus {
    border-color: #ff9000;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px #f6be69;
}


/*
* Estilo para los componentes de entrada dentro de los formularios
*/

.form-control {
    display: block;
    width: 100%;
    //height: calc(1.5em + 0.1rem + 0px);
    height: 31px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


/*
.input-group-prepend {
    margin-right: -1px;
    //height: calc(1.5em + 0.1rem + 4px);
    height: 31px;
}
*/


/*
label {
    display: inline-block;
    margin-top: 0.3rem;
    margin-bottom: 0.2rem;
}
*/


/*
.custom-select {
    display: inline-block;
    width: 100%;
    //height: calc(1.5em + 0.1rem + 0px);
    height: 31px;
    padding: 0.1rem 0.3rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
*/

//************************************************************
.wrapper {
    //height: 100%;
    main {
        display: flex;
        height: 100%;
        .pages {
            overflow: auto;
        }
        .overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 100;
            opacity: 0.7;
            display: none;
            &.show {
                display: block;
                @media (min-width: 768px) {
                    display: none;
                }
            }
        }
    }
    &.toggeled-sidebar {
        main {
            .overlay {
                display: block;
                @media (min-width: 768px) {
                    display: none;
                }
            }
        }
    }
}

.columnaNumero {
    text-align: right;
}

.toast {
    opacity: 1;
}

.badge {
    font-weight: normal;
    font-size: 100%;
}

.formulario {
    min-height: 100px;
    background-color: #fff;
    padding: 15px 15px;
    margin-bottom: 10px;
    border: 1px solid #9e9e9e;
    border-radius: 3px;
}

.PiePagina {
    background-color: #c7f5a1;
    margin-bottom: 10px;
    height: 40px;
    color: #000;
    padding-left: 10px;
    font-size: 24px;
}

.SubTitulo {
    background-color: #bdbaba;
    margin-left: -22px;
    margin-top: -24px;
    margin-bottom: 10px;
    width: 105%;
    height: 40px;
    color: #000;
    text-align: right;
    padding-right: 40px;
    vertical-align: middle;
    font-size: 24px;
}

.SubTituloTabla {
    background-color: #bdbaba;
    padding: 5px 10px;
    margin-top: 10px;
}

.btnMargen {
    margin-right: 5px;
    margin-bottom: 5px;
}


/*
.e-grid .e-rowcell {
    border-style: solid;
    border-width: 1px 0 0 0;
    display: table-cell;
    font-size: 13px;
    line-height: 20px;
    overflow: hidden;
    padding: 0px 5px;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
}

.e-chip-list.e-chip,
.e-chip-list .e-chip {
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    border: 0 solid;
    border-radius: 14px;
    box-shadow: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    font-weight: normal;
    height: 22px;
    justify-content: center;
    line-height: 1.5em;
    margin: 1px;
    outline: none;
    overflow: hidden;
    padding: 0 10px;
    position: relative;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    user-select: none;
}
*/

.grupo {
    padding: 5px 5px;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 4px;
    border-left-color: #428bca;
    background-color: rgb(66, 139, 202, 0.2);
    margin-top: 10px;
    margin-bottom: 10px;
}

.etiqueta {
    color: #fff;
    border-radius: 15px;
    padding: 2px 5px;
    text-align: center;
}

.estatus {
    color: #fff;
    border-radius: 5px;
    padding: 0px 5px;
    text-align: center;
}

.progress {
    display: flex;
    height: 20px;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.progress-kanban {
    display: flex;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 8px;
    margin-top: 3px;
    height: 12px;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #007bff;
    transition: width 0.6s ease;
}

.dialogo-titulo-error {
    background-color: #dc3545;
    padding: 4px 10px;
    color: #fff;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error),
.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error) {
    border-color: #ccc;
    //height: 100%;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0rem 0.3rem 0rem 0.3rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}