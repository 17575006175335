.table {
    font-size: 14px;
    th,
    td {
        border-top: 1px solid darken($border-color, 2%);
        padding: 0.1rem;
    }
    thead th {
        border-bottom: 2px solid darken($border-color, 2%);
        background-color: $titulo-colorfondo;
        text-align: center;
    }
    &.table-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.05);
    }
    &.table-hover tbody tr:hover {
        background-color: lighten($border-color, 1%);
    }
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: #ffc400;
}