.ribbon-horizontal {
    position: absolute;
    right: -1px;
    top: 0px;
    z-index: 1;
    overflow: hidden;
    width: 100px;
    height: 75px;
    text-align: right;
}

.ribbon-horizontal span {
    font-size: 12px;
    color: #fff;
    text-align: right;
    line-height: 30px;
    width: 100px;
    display: block;
    background: #39d;
    background: linear-gradient(#39d 0%, #39d 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 5px;
    border-radius: 5px 0px 0px 5px;
    padding-right: 5px;
}