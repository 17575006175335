.ribbon-label {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbon-label span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #e82;
    background: linear-gradient(rgb(243, 164, 84) 0%, #e82 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
}