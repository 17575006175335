.btn {
    margin-right: 3px;
    padding: 0rem 0.5rem;
    margin-right: 5px;
}

.btn-pregunta {
    padding: 0px 0px;
    width: 25px;
    height: 23px;
    margin-left: 5px;
}

.input-group .btn {
    margin: 0;
    border-radius: 0;
}

.input-group .input-group-text {
    margin: 0;
    border-radius: 0.25rem 0px 0px 0.25rem;
}

.btn-color-white {
    background: #f0f0f0;
    color: #555;
}

.btn-color-black {
    background: #333;
}

.btn-color-grey {
    background: #999;
}

.btn-color-blue {
    background: #39d;
}

.btn-color-green {
    background: #2c7;
    color: #161616;
}

.btn-color-turquoise {
    background: #1b9;
}

.btn-color-purple {
    background: #95b;
}

.btn-color-red {
    background: #e43;
    color: #f0f0f0;
}

.btn-color-orange {
    background: #e82;
}

.btn-color-yellow {
    background: #ec0;
}


/*Boton Nuevo para colocar estilo*/

.btn-nuevo {
    position: relative;
    color: #2196f3;
    border-color: #2196f3;
    background-color: transparent;
}

.btn-nuevo:hover {
    background-color: #2196f3;
    color: #fff;
}

.btn-nuevo::before {
    content: "\f0fe";
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Guardar para colocar estilo*/

.btn-guardar {
    position: relative;
    color: #28a745;
    border-color: #28a745;
    background-color: transparent;
}

.btn-guardar:hover {
    background-color: #28a745;
    color: #fff;
}

.btn-guardar::before {
    content: "\f0c7";
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}

/*Boton Aceptar para colocar estilo*/

.btn-aceptar {
    position: relative;
    color: #28a745;
    border-color: #28a745;
    background-color: transparent;
}

.btn-aceptar:hover {
    background-color: #28a745;
    color: #fff;
}

.btn-aceptar::before {
    content: "\f058";
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Cancelar para colocar estilo*/

.btn-cancelar {
    position: relative;
    color: #dc3545;
    border-color: #dc3545;
    background-color: transparent;
}

.btn-cancelar:hover {
    background-color: #dc3545;
    color: #fff;
}

.btn-cancelar::before {
    content: "\f057";
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Procesar para colocar estilo*/

.btn-procesar {
    position: relative;
    color: #5b6582;
    border-color: #5b6582;
    background-color: transparent;
}

.btn-procesar:hover {
    background-color: #5b6582;
    color: #fff;
}

.btn-procesar::before {
    content: "\f02e";
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Correo para colocar estilo*/

.btn-correo {
    position: relative;
    color: #5b6582;
    border-color: #5b6582;
    background-color: transparent;
}

.btn-correo:hover {
    background-color: #5b6582;
    color: #fff;
}

.btn-correo::before {
    content: "\f0e0";
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Imprimir para colocar estilo*/

.btn-imprimir {
    position: relative;
    color: #5b6582;
    border-color: #5b6582;
    background-color: transparent;
}

.btn-imprimir:hover {
    background-color: #5b6582;
    color: #fff;
}

.btn-imprimir::before {
    content: "\f02f";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Mensaje para colocar estilo*/

.btn-mensaje {
    position: relative;
    color: #5b6582;
    border-color: #5b6582;
    background-color: transparent;
}

.btn-mensaje:hover {
    background-color: #5b6582;
    color: #fff;
}

.btn-mensaje::before {
    content: "\f27a";
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Copiar para colocar estilo*/

.btn-copiar {
    position: relative;
    color: #5b6582;
    border-color: #5b6582;
    background-color: transparent;
}

.btn-copiar:hover {
    background-color: #5b6582;
    color: #fff;
}

.btn-copiar::before {
    content: "\f0c5";
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Editar para colocar estilo*/

.btn-editar {
    position: relative;
    color: #28a745;
    border-color: #28a745;
    background-color: transparent;
}

.btn-editar:hover {
    background-color: #28a745;
    color: #fff;
}

.btn-editar::before {
    content: "\f044";
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Editar para colocar estilo para Tablas*/

.btn-editar-sm {
    position: relative;
    color: #28a745;
    border-color: #28a745;
    padding: 0rem 0.2rem;
    background-color: transparent;
}

.btn-editar-sm:hover {
    background-color: #28a745;
    color: #fff;
}

.btn-editar-sm::before {
    content: "\f044";
    font-family: "Font Awesome 5 Free";
    transition: all 0.3s ease 0s;
}


/*Boton Borrar para colocar estilo*/

.btn-borrar {
    position: relative;
    color: #dc3545;
    border-color: #dc3545;
    background-color: transparent;
}

.btn-borrar:hover {
    background-color: #dc3545;
    color: #fff;
}

.btn-borrar::before {
    content: "\f2ed";
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Borrar para colocar estilo para Tablas*/

.btn-borrar-sm {
    position: relative;
    color: #dc3545;
    border-color: #dc3545;
    padding: 0rem 0.2rem;
    background-color: transparent;
}

.btn-borrar-sm:hover {
    background-color: #dc3545;
    color: #fff;
}

.btn-borrar-sm::before {
    content: "\f2ed";
    font-family: "Font Awesome 5 Free";
    transition: all 0.3s ease 0s;
}


/*Boton Buscar para colocar estilo*/

.btn-buscar {
    position: relative;
    color: #5b6582;
    border-color: #ced4da;
    background-color: transparent;
}

.btn-buscar:hover {
    background-color: #5b6582;
    color: #fff;
}

.btn-buscar::before {
    content: "\f002";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}


/*Boton Convertir para colocar estilo*/

.btn-convertir {
    position: relative;
    color: #5b6582;
    border-color: #5b6582;
    background-color: transparent;
}

.btn-convertir:hover {
    background-color: #5b6582;
    color: #fff;
}

.btn-convertir::before {
    content: "\f337";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    vertical-align: middle;
    font-size: 18px;
    padding-right: 5px;
    transition: all 0.3s ease 0s;
}